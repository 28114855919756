import React from 'react'
import {
  Breadcrumbs,
  Hero,
  Notice,
  Page,
} from '@components'

const meta = {
  title: 'Napomena | Protupožarna zaštita - FSB d.o.o.',
  description: 'Možete nas kontaktirati na telefon i email: technical@fsb-zagreb.hr',
}

const Homepage = () => (
  <Page
    isHomepage
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
      ]}
    />
    <Hero
      slim
      subtitle="Napomena"
    />
    <Notice>
      <p>
        Protupožarno brtvljenje ima važnu ulogu na svakoj građevini jer u slučaju požara sprječava širenje vatre i dima preko granica požarnih sektora.
        U slučaju oštećenja vrlo je važno ponovno uspostaviti funkcionalnost prema projektu izvedenog stanja koji smo predali investitoru.
      </p>
      <p>
        Možete nas kontaktirati na telefon <a href="tel:+38513496811">+385 1 3496 811</a> i email: <a href="mailto:technical@fsb-zagreb.hr">technical@fsb-zagreb.hr</a>
      </p>
    </Notice>
  </Page>
)

export default Homepage